<template>

  <div>
    <v-breadcrumbs
        :items="pathModul"
        divider="-"
        normal
        class="pathModuls"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container class="container-filtering">

        <v-row align="center">

            <!-- Column Select Station -->
            <v-col
            class="col-input-filter"
            >
                <v-select
                v-on:change="selectStation"
                :items="itemStation"
                item-value="station_id"
                item-text="name"
                label="Station"
                ></v-select>
            </v-col>

            <!-- Column Select Calendar -->
            <v-col
            class="col-input-filter"
            >
                <v-menu
                v-model="modalCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="valueFromDate"
                    label="From Date"
                    prepend-icon="mdi-calendar"

                    readonly
                    v-bind="attrs"
                    v-on="on"
                    >
                    </v-text-field>
                </template>
                <v-date-picker
                    v-model="valueFromDate"
                    v-on:change="selectFromDate"
                    @input="modalCalendar = false"
                >
                    <v-spacer></v-spacer>
                </v-date-picker>
                </v-menu>
            </v-col>

            <!-- Column Select Calendar2 -->
            <v-col
            class="col-input-filter"
            >
                <v-menu
                v-model="modalCalendar2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="valueToDate"
                    label="To Date"
                    prepend-icon="mdi-calendar"

                    readonly
                    v-bind="attrs"
                    v-on="on"
                    >
                    </v-text-field>
                </template>
                <v-date-picker
                    v-model="valueToDate"
                    v-on:change="selectToDate"
                    @input="modalCalendar2 = false"
                >
                    <v-spacer></v-spacer>
                </v-date-picker>
                </v-menu>
            </v-col>

            <!-- Column Generate Button -->
            <v-col
            cols="12"
            sm="6"
            md="4"
            class="col-btn-generateReport"
            >
                <v-btn
                class="ma-2 white--text btn-generate primary"
                solo
                dense
                normal
                @click="getExceedance"
                >
                    Generate Report
                    <v-icon
                    right
                    dark
                    big
                    >
                        mdi-table-arrow-up
                    </v-icon>
                </v-btn>

                <v-btn
                color="green"
                elevation="2"
                @click="exportReportExceed"
                >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-microsoft-excel
                    </v-icon>
                </v-btn>
            </v-col>

        </v-row>

    </v-container>

    <v-container>

        <v-subheader
            class="subTitles fontSubTitle-blue"
            v-if="queryValue == true"
        >
            <!-- EXCEEDANCE ALERT RECORD FOR {{ valueStation }} ({{ valueFromDate }} TO {{ valueToDate }}) -->
            Exceedance Alert Record For {{ valueStation }} ({{ this.changeDateFormat(valueFromDate) }} TO {{ this.changeDateFormat(valueToDate) }})
        </v-subheader>

        <!-- Table -->
        <v-data-table
            :headers="thead_alertexceedance"
            :items="tbody_alertexceedance"
            :items-per-page="5"
            class="elevation-1 headerDtSarawak"
        >
        <!-- <template v-slot:header="{ props: { headers } }">
            <thead>
                <tr>
                    <th v-for="(h,key) in headers" 
                    :key="key"
                    divider='true'
                    class="table-header">
                    <span>{{h.text}}</span>
                    </th>
                </tr>
            </thead>
        </template> -->

        <template v-slot:[`item.parameterSelected`]="{ item }">
            <div v-html="item.parameterSelected"></div>
        </template>

        <template v-slot:[`item.notes`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.notes"
            large
            persistent
            @save="saveExceed"
            @cancel="cancelExceed"
            @open="openExceed"
            @close="closeExceed"
          >
            <div>{{ props.item.notes }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Update Notes
              </div>
              <v-text-field
                v-model="props.item.notes"
                label="Edit"
                single-line
                counter
                autofocus
                @input="updateNotesExceed = { newId: props.item.newId, notes: $event}"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        
        </v-data-table>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
        >
            {{ snackText }}
    
            <template v-slot:action="{ attrs }">
            <v-btn
                v-bind="attrs"
                text
                @click="snack = false"
            >
                Close
            </v-btn>
            </template>
        </v-snackbar>

    </v-container>

  </div>

</template>

<script>

import axios from 'axios';
// import XLSX from 'xlsx';
import Papa from "papaparse";

export default {
    
    data: () => ({

        pathModul: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Alert - Exceedance Alert Record',
                disabled: true,
                href: '/Alert/Exceedance',
            },
        ],
        itemStation: [],
        // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modalCalendar: false,
        modalCalendar2: false,

        thead_alertexceedance: [
          { text: 'STATION ID', value: 'stationId', divider: true, align: 'center', sortable: false },
          { text: 'DATETIME', value: 'datetime', divider: true, align: 'center' },
          { text: 'EXCEEDANCE', value: 'parameterSelected', divider: true, align: 'center' },
          { text: 'NOTES', value: 'notes', divider: true, align: 'center' },
        //   { text: 'UPDATED BY', value: 'notesBy', divider: true, align: 'center' },
          { text: 'UPDATED ON', value: 'notesUpdatedDt', divider: true, align: 'center' },
        ],
        tbody_alertexceedance: [],

        //export excel
        thead_alertexceedanceReport: [
          { text: 'STATION ID', value: 'stationId', divider: true, align: 'center', sortable: false },
          { text: 'DATETIME', value: 'datetime', divider: true, align: 'center' },
          { text: 'EXCEEDANCE', value: 'paramSelect', divider: true, align: 'center' },
          { text: 'NOTES', value: 'notes', divider: true, align: 'center' },
        //   { text: 'UPDATED BY', value: 'notesBy', divider: true, align: 'center' },
          { text: 'UPDATED ON', value: 'notesUpdatedDt', divider: true, align: 'center' },
        ],
        tbody_alertexceedanceReport: [],

        valueStation : null,
        valueFromDate : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        valueToDate : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        queryValue : false,

        snack: false,
        snackColor: '',
        snackText: '',
        pagination: {},
        updateNotesExceed: {
            newId: '',
            notes: '',
        },
        
    }),

    methods: {

        selectStation(stationId){
            // console.log(stationId);
            this.valueStation = stationId;

        },

        selectFromDate(fromDate){
            // console.log(fromDate);
            this.valueFromDate = fromDate;
        },

        selectToDate(toDate){
            // console.log(toDate);
            this.valueToDate = toDate;
        },

        changeDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
        },

        changeDatetimeFormat(datetime){

            if(datetime != '' && datetime != null){
                let [date, time] = datetime.split('T');

                //convert date 
                var dd = date.split("-")[2];
                var mm = date.split("-")[1];
                var yyyy = date.split("-")[0];
                date = dd + "/" + mm + "/" + yyyy;

                // Convert Time to 12 hours
                var H = +time.substr(0, 2);
                var h = (H % 12) || 12;
                var ampm = H < 12 ? "AM" : "PM";
                time = h + time.substr(2, 3) + time.substr(5, 3) + ampm;

                datetime = date + ", " + time;
                return datetime;

            }else {
                return datetime = null;
            }
        },

        getStationExceedance(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
            .then((response) => {

            let stationInfo = [
                {
                    station_id: 'all',
                    name: 'All Station'
                }];

            response.data.forEach(function(res){
                let objStation = {}
                objStation['station_id'] = res.stationId;
                objStation['name'] = res.stationId + ' - ' + res.location;
                stationInfo.push(objStation);
            });

            this.itemStation = stationInfo;
          })
            .catch(error => {
              console.log(error);
          })

        },

        getExceedance(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/exceedance?station='+ this.valueStation +'&startdt='+ this.valueFromDate +'&enddt='+this.valueToDate, {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
            .then((response) => {

                
                let displayData = [];
                 
                response.data.forEach(function(res){
                  if(res.class == 'V' && res.category == 'WQI'){
                      res['parameterSelected'] = '<span class="v-chip theme--dark v-size--default white--text" style="background-color: #FB401D; margin-top: 3px;"> Class : ' + res.class + '</span> |WQI : ' + res.wqi ;
                    //   res['parameterSelected'] = '<span class="v-chip theme--dark v-size--default white--text" style="background-color: #FB401D; margin-top: 3px;"> Class : ' + res.class + '</span> |WQI : ' + res.wqi + ' |' + res.paramSelected ;
                      res['parameterSelected'] = res.parameterSelected.replaceAll('|', '<br>');
                      displayData.push(res);
                  }
                  else if(res.class == 'IV' && res.category == 'WQI'){
                      res['parameterSelected'] = '<span class="v-chip theme--dark v-size--default white--text" style="background-color: #FFAB3D; margin-top: 3px;"> Class : ' + res.class + '</span> |WQI : ' + res.wqi ;
                    //   res['parameterSelected'] = '<span class="v-chip theme--dark v-size--default white--text" style="background-color: #FFAB3D; margin-top: 3px;"> Class : ' + res.class + '</span> |WQI : ' + res.wqi + ' |' + res.paramSelected ;
                      res['parameterSelected'] = res.parameterSelected.replaceAll('|', '<br>');
                      displayData.push(res);
                      
                  } 
                });
                this.tbody_alertexceedance = displayData;
                for (let i=0; i < displayData.length; i++){
                    displayData[i].datetime = this.changeDatetimeFormat(displayData[i].datetime);
                    displayData[i].notesUpdatedDt = this.changeDatetimeFormat(displayData[i].notesUpdatedDt);
                }

                //export excel
                let exportData = [];
                 
                response.data.forEach(function(res){
                  if(res.class == 'V' && res.category == 'WQI'){
                      res['paramSelect'] = ' Class : ' + res.class + ' |WQI : ' + res.wqi ;
                    //   res['paramSelect'] = ' Class : ' + res.class + ' |WQI : ' + res.wqi + ' |' + res.paramSelected ;
                      exportData.push(res);
                  }
                  else if(res.class == 'IV' && res.category == 'WQI'){
                      res['paramSelect'] = ' Class : ' + res.class + ' |WQI : ' + res.wqi ;
                    //   res['paramSelect'] = ' Class : ' + res.class + ' |WQI : ' + res.wqi + ' |' + res.paramSelected ;
                      exportData.push(res);
                      
                  } 
                });
                this.tbody_alertexceedanceReport = exportData;
                for (let i=0; i < exportData.length; i++){
                    exportData[i].datetime = this.changeDatetimeFormat(exportData[i].datetime);
                    exportData[i].notesUpdatedDt = this.changeDatetimeFormat(exportData[i].notesUpdatedDt);
                }
                this.queryValue = true;

          })
            .catch(error => {
              console.log(error);
          })

        },

        // open modal notes
        saveExceed () {
            const that = this;
            axios.put(`https://apis.spatialworks.com.my/sarawak/cms/exceedance`, this.updateNotesExceed,{
                headers: {
                    Authorization: 'Bearer ' + this.$store.getters.user.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    that.getExceedance();
                })
                .catch(error => {
                    console.log(error);
                })
        console.log(this.updateNotesExceed);
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
        },
        cancelExceed () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
        },
        openExceed () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
        },
        closeExceed () {
        console.log('Dialog closed')
        },

        exportReportExceed (){
            // const data = XLSX.utils.json_to_sheet(this.tbody_alertexceedance);
            // data['!cols'] = [];
            // data['!cols'][10] = { hidden: true };
            // const wb = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(wb, data, 'data');
            // XLSX.writeFile(wb,'Exceedance Alert Record For ' + this.valueStation + ' (' + this.changeDateFormat(this.valueFromDate) + ' TO ' + this.changeDateFormat(this.valueToDate) + ').xlsx');
            // console.log(this.tbody_alertexceedance);
            let headerExceed = [];
            for (let i = 0; i < this.thead_alertexceedanceReport.length; i++) {
                headerExceed.push(this.thead_alertexceedanceReport[i].value);
            }

            let keysExceed = headerExceed,
            result = this.tbody_alertexceedanceReport.map(o => Object.assign(...keysExceed.map(k => ({ [k]: o[k] }))));

            console.log(result);

            var blob = new Blob([Papa.unparse(result)], { type: 'text/csv;charset=utf-8;' });

            var link = document.createElement("a");

            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'Exceedance Alert Record For ' + this.valueStation + ' (' + this.changeDateFormat(this.valueFromDate) + ' TO ' + this.changeDateFormat(this.valueToDate) + ').csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },

    mounted(){
        this.getStationExceedance();
    }
}

</script>

<style lang="scss">

@import '~scss/main';

.pathModuls{
    padding-bottom: 0px;
}

.pathModuls > li:nth-child(3) > a{
    color: black !important;
}

.container-filtering{
    padding: 0px;
}

.col-input-filter{
    padding: 0px 10px;
}

.col-btn-generateReport{
    margin-bottom: 18px;
}

.btn-generate{
    margin: 0px !important;
    // background: #4495D1 !important;
}

.container-filtering::v-deep .v-text-field.v-text-field--solo .v-input__control,
.btn-generate .v-btn .v-btn--contained{
    min-height: 10px;
    margin-top: 10px;
}

.subTitles{
    margin-left: 28%;
    // color: #1976D2 !important;
}

// .table-header {
//     white-space: nowrap;
//     padding: 16px !important;
//     // background: #4495D1;
//     // color: white !important;
//     text-align: center !important;
//     border: 1px solid rgba(0, 0, 0, 0.12);
// }

</style>